<template>
  <b-card>
    <div
      v-for="(row, index) in editSite.webhooks"
      :key="index"
      class="row mt-2"
    >
      <div class="col-10 col-md-5 mb-1 mb-md-0">
        <b-form-input
          v-model="row.url"
          placeholder="Введите url"
        />
      </div>
      <div class="col-2">
        <b-button
          v-b-tooltip.hover.top="'Удалить строку'"
          size="sm"
          variant="link"
          @click.stop="removeRow(index)"
        >
          <feather-icon
            icon="Trash2Icon"
            size="18"
          />
        </b-button>
      </div>
    </div>
    <b-button
      v-b-tooltip.hover.right="'Добавить строку'"
      size="sm"
      variant="primary"
      class="d-flex align-items-center mt-1"
      @click.stop="addRow()"
    >
      Добавить строку
      <feather-icon
        icon="PlusIcon"
        size="18"
        class="ml-1"
      />
    </b-button>
    <div class="mt-3">
      <div class="d-flex">
        <b-button
          class="mr-1"
          variant="primary"
          @click.prevent="onSave"
        >
          Сохранить
        </b-button>
        <b-button
          variant="outline-primary"
          @click.prevent="onCancel"
        >
          Отменить изменения
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BFormInput, BButton, VBTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Site from '../../api/http/models/site/site/Site'

export default {
  components: {
    BCard,
    BFormInput,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      site: {
        webhooks: [],
      },
      editSite: {
        webhooks: [
          {
            url: '',
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const response = await Site.getOne(this.currentSite.slug)

    if (response.data) {
      this.site = response.data
      this.editSite = JSON.parse(JSON.stringify(this.site))
    }
  },
  methods: {
    addRow() {
      this.editSite.webhooks.push({
        name: '',
        count: '',
      })
    },
    removeRow(index) {
      this.editSite.webhooks.splice(index, 1)
    },
    async onSave() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const responseWebhooks = await Site.updateWebhooks(this.currentSite.slug, {
          webhooks: this.editSite.webhooks,
        })

        if (responseWebhooks.status === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Настройки сохранены',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else if (responseWebhooks.errors) {
          this.mixinErrorsParse(responseWebhooks.errors)
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Некоторые поля не заполнены или заполнены неверно',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    onCancel() {
      this.editSite = JSON.parse(JSON.stringify(this.site))
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Изменения отменены',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
  },
}
</script>
